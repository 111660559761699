/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.icon-btn {
  border: 0 none;
  color: #6b7280;
  background-color: transparent;
  box-shadow: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5px;
}
.icon-btn:hover,
.icon-btn:focus {
  background-color: rgba(0, 0, 0, 0.05);
  color: #111827;
}
.icon-btn.active {
  color: #0A8FDC;
}
.icon-btn.active:hover,
.icon-btn.active:focus {
  background-color: rgba(0, 0, 0, 0.05);
  color: #0A8FDC;
}
.icon-btn svg {
  display: block;
  font-size: 18px;
}
@media screen and (min-width: 1600px) {
  .icon-btn svg {
    font-size: 20px;
  }
}
