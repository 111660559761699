/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.timeline-image {
  width: 100%;
}
@media screen and (max-width: 576px) {
  .timeline-image {
    padding-bottom: 16px;
  }
}
.timeline-image .rt-backing {
  border-radius: 16px;
  background-color: #fff;
  color: #111827;
  padding: 20px;
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
}
.timeline-image .rt-header-container {
  background-color: transparent;
}
.timeline-image .rt-title {
  color: #111827;
}
.timeline-image .rt-title {
  font-size: 16px;
  font-weight: 600 !important;
}
.timeline-image .rt-date {
  font-weight: 400 !important;
}
.timeline-image .rt-text-container {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
}
.timeline-image .rt-text-container p:last-child {
  margin-bottom: 0;
}
.timeline-image .rt-footer-container {
  border-top: solid 1px rgba(0, 0, 0, 0.12);
  margin-left: -20px;
  margin-right: -20px;
}
.timeline-image .rt-label {
  background-color: #F04F47;
  width: 60px;
  height: 60px;
  font-size: 20px;
}
.timeline-image .rt-btn {
  color: #F04F47;
  background-color: transparent;
  border: 0 none;
  font-weight: 400 !important;
  padding-bottom: 0;
  font-size: 14px;
}
.timeline-image .rt-event {
  width: 50%;
  padding-left: 45px;
}
[dir=rtl] .timeline-image .rt-event {
  padding-left: 0;
  padding-right: 45px;
  float: left;
  clear: left;
}
.timeline-image .rt-event .rt-svg-container .rt-arrow {
  color: #F04F47;
  left: -45px;
}
[dir=rtl] .timeline-image .rt-event .rt-svg-container .rt-arrow {
  left: auto;
  right: -39px;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
}
.timeline-image .rt-event .rt-dot {
  background-color: #fff;
  color: #F04F47;
  border: solid 2px #F04F47;
  left: -80px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
[dir=rtl] .timeline-image .rt-event .rt-dot {
  left: auto;
  right: -80px;
}
.timeline-image .rt-event:nth-of-type(2n) {
  padding-left: 0;
  padding-right: 45px;
}
[dir=rtl] .timeline-image .rt-event:nth-of-type(2n) {
  padding-left: 45px;
  padding-right: 0;
}
.timeline-image .rt-event:nth-of-type(2n) .rt-svg-container .rt-arrow {
  right: -45px;
  left: auto;
}
[dir=rtl] .timeline-image .rt-event:nth-of-type(2n) .rt-svg-container .rt-arrow {
  right: auto;
  left: -45px;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.timeline-image .rt-event:nth-of-type(2n) .rt-dot {
  right: -80px;
}
[dir=rtl] .timeline-image .rt-event:nth-of-type(2n) .rt-dot {
  right: auto;
  left: -80px;
}
.rt-timeline-container {
  padding: 1em;
  font-family: 'Roboto', serif !important;
  justify-content: center;
  align-items: center;
  display: flex;
}
.rt-timeline {
  max-width: 1600px;
  width: 100%;
  padding: 0;
  list-style-type: none;
  position: relative;
}
.rt-timeline:after {
  left: calc(50% - 0.5px);
  top: 1px;
  z-index: 1;
  width: 1px;
  height: 100%;
  content: '';
  position: absolute;
  background-color: #d5d6d8;
}
[dir=rtl] .rt-timeline:after {
  left: auto;
  right: calc(50% - 0.5px);
}
.rt-label-container {
  clear: both;
  margin: 1em auto 1em auto;
  display: flex;
  justify-content: center;
}
.rt-label-container:first-of-type {
  margin-top: 0 !important;
}
.rt-label-container:last-of-type {
  margin-bottom: 0;
}
.rt-label {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  border-radius: 50%;
  font-size: 24px;
  font-weight: 500 !important;
  width: 3em;
  height: 3em;
  position: relative;
  z-index: 3;
}
.rt-clear {
  clear: both;
}
.rt-backing {
  padding: 20px;
}
.rt-event {
  position: relative;
  float: right;
  clear: right;
  width: 50%;
  margin-top: 2em;
  margin-left: 0;
  margin-right: 0;
  padding-left: 65px;
  list-style-type: none;
  display: block;
  min-height: 500px;
}
[dir=rtl] .rt-event {
  padding-left: 0;
  padding-right: 65px;
}
.rt-event .rt-dot {
  position: absolute;
  margin-top: 20px;
  left: -101px;
  right: auto;
  fill: currentcolor;
  font-size: 14px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  z-index: 100;
}
[dir=rtl] .rt-event .rt-dot {
  left: auto;
  right: -101px;
}
.rt-event .rt-svg-container {
  position: relative;
}
.rt-event .rt-svg-container svg {
  transform: scale(-1, 1);
}
.rt-event .rt-svg-container .rt-arrow {
  z-index: 100;
  position: absolute;
  margin-top: 20px;
  left: -42px;
  right: auto;
  fill: currentcolor;
  width: 23px;
}
[dir=rtl] .rt-event .rt-svg-container .rt-arrow {
  left: auto;
  right: -42px;
}
.rt-event:nth-of-type(2n) {
  float: left;
  clear: left;
  text-align: right;
  padding-left: 0;
  padding-right: 65px;
}
[dir=rtl] .rt-event:nth-of-type(2n) {
  float: right;
  clear: right;
  text-align: left;
  padding-left: 65px;
  padding-right: 0;
}
.rt-event:nth-of-type(2n) svg {
  transform: scale(1, 1);
}
.rt-event:nth-of-type(2n) .rt-arrow {
  left: auto;
  right: -42px;
}
[dir=rtl] .rt-event:nth-of-type(2n) .rt-arrow {
  left: -42px;
  right: auto;
}
.rt-event:nth-of-type(2n) .rt-dot {
  left: auto;
  right: -101px;
}
[dir=rtl] .rt-event:nth-of-type(2n) .rt-dot {
  left: -101px;
  right: auto;
}
.rt-timeline li:nth-child(3) {
  margin-top: 250px;
}
.rt-title {
  margin-bottom: 8px;
  font-size: 18px;
  font-weight: var(--medium) !important;
}
.rt-date {
  margin: 0;
  color: #a8a8a8;
  font-weight: var(--medium) !important;
  font-size: 14px;
}
.rt-header-container {
  margin-bottom: 30px;
}
.rt-image-container {
  margin-bottom: 15px;
}
.rt-image {
  vertical-align: top;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  width: 100%;
  height: auto;
  max-width: 100vw;
  max-height: 250px;
  object-fit: cover;
}
.rt-footer-container {
  margin: 15px -20px -20px;
  padding: 20px;
  text-align: center;
}
.rt-btn {
  text-align: center;
  font-size: 16px;
  font-weight: var(--medium) !important;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: none;
  text-transform: capitalize;
}
.rt-text-container {
  max-height: 200px;
  font-weight: 300;
  overflow: hidden;
  text-overflow: ellipsis;
  content: '';
  position: relative;
  text-align: left;
}
[dir=rtl] .rt-text-container {
  text-align: right;
}
@media all and (max-width: 1919px) {
  .rt-label {
    font-size: 20px;
  }
  .rt-event {
    padding-left: 45px;
  }
  [dir=rtl] .rt-event {
    padding-left: 0;
    padding-right: 45px;
  }
  .rt-event:nth-of-type(2n) {
    padding-right: 45px;
  }
  [dir=rtl] .rt-event:nth-of-type(2n) {
    padding-right: 0;
    padding-left: 45px;
  }
  .rt-event .rt-dot {
    left: -81px;
  }
  [dir=rtl] .rt-event .rt-dot {
    left: auto;
    right: -81px;
  }
  .rt-event:nth-of-type(2n) .rt-dot {
    right: -81px;
  }
  [dir=rtl] .rt-event:nth-of-type(2n) .rt-dot {
    right: auto;
    left: -81px;
  }
  .rt-header-container {
    margin-bottom: 20px;
  }
  .rt-title {
    font-size: 16px;
  }
}
@media all and (max-width: 767px) {
  .rt-timeline-container {
    padding: 0;
  }
  .timeline-image .rt-event {
    width: 100%;
    padding-left: 90px;
    min-height: 100px;
    margin-bottom: 30px;
    margin-top: 0;
    float: none;
    clear: none;
  }
  [dir=rtl] .timeline-image .rt-event {
    padding-left: 0;
    padding-right: 90px;
    float: none;
    clear: none;
  }
  .timeline-image .rt-event:nth-of-type(2n) {
    padding-right: 0;
    padding-left: 90px;
    text-align: left;
    float: none;
    clear: none;
  }
  [dir=rtl] .timeline-image .rt-event:nth-of-type(2n) {
    padding-right: 90px;
    padding-left: 0;
    text-align: right;
  }
  .rt-backing {
    padding: 20px;
  }
  .timeline-image .rt-event .rt-svg-container .rt-arrow {
    left: -43px;
  }
  [dir=rtl] .timeline-image .rt-event .rt-svg-container .rt-arrow {
    left: auto;
    right: -43px;
  }
  .timeline-image .rt-event:nth-of-type(2n) .rt-svg-container .rt-arrow {
    left: -43px;
    right: auto;
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  [dir=rtl] .timeline-image .rt-event:nth-of-type(2n) .rt-svg-container .rt-arrow {
    left: auto;
    right: -43px;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  .timeline-image .rt-event .rt-dot {
    left: -95px;
  }
  [dir=rtl] .timeline-image .rt-event .rt-dot {
    left: auto;
    right: -95px;
  }
  .timeline-image .rt-event:nth-of-type(2n) .rt-dot {
    right: auto;
    left: -95px;
  }
  [dir=rtl] .timeline-image .rt-event:nth-of-type(2n) .rt-dot {
    right: -95px;
    left: auto;
  }
  .rt-label-container {
    justify-content: flex-start;
  }
  .rt-timeline:after {
    left: 30px;
  }
  [dir=rtl] .rt-timeline:after {
    left: auto;
    right: 30px;
  }
  .rt-timeline li:nth-child(3) {
    margin-top: 0;
  }
  .rt-footer-container {
    margin: 15px -20px -20px;
    padding: 15px 20px;
  }
  .rt-label-container:last-of-type {
    margin-top: 0;
  }
}
@media all and (max-width: 499px) {
  .rt-timeline-container {
    padding: 12px;
    min-width: 300px;
    margin-left: -16px;
  }
  [dir=rtl] .rt-timeline-container {
    margin-left: 0;
    margin-right: -16px;
  }
  .timeline-image .rt-event:nth-of-type(2n),
  .timeline-image .rt-event {
    padding-left: 70px;
  }
  [dir=rtl] .timeline-image .rt-event:nth-of-type(2n),
  [dir=rtl] .timeline-image .rt-event {
    padding-left: 0;
    padding-right: 70px;
  }
  .timeline-image .rt-event:nth-of-type(2n) .rt-dot,
  .timeline-image .rt-event .rt-dot {
    left: -80px;
  }
  [dir=rtl] .timeline-image .rt-event:nth-of-type(2n) .rt-dot,
  [dir=rtl] .timeline-image .rt-event .rt-dot {
    left: auto;
    right: -80px;
  }
  .rt-label {
    font-size: 16px;
  }
  .rt-timeline::after {
    left: 25px;
  }
  [dir=rtl] .rt-timeline::after {
    left: auto;
    right: 25px;
  }
  .rt-event,
  .rt-event:nth-of-type(2n) {
    padding-left: 70px;
  }
  [dir=rtl] .rt-event,
  [dir=rtl] .rt-event:nth-of-type(2n) {
    padding-left: 0;
    padding-right: 70px;
  }
  .rt-event .rt-dot,
  .rt-event:nth-of-type(2n) .rt-dot {
    left: -80px;
  }
  [dir=rtl] .rt-event .rt-dot,
  [dir=rtl] .rt-event:nth-of-type(2n) .rt-dot {
    left: auto;
    right: -80px;
  }
  .rt-event .rt-svg-container .rt-arrow,
  .rt-event:nth-of-type(2n) .rt-arrow {
    left: -40px;
  }
  [dir=rtl] .rt-event .rt-svg-container .rt-arrow,
  [dir=rtl] .rt-event:nth-of-type(2n) .rt-arrow {
    left: auto;
    right: -40px;
  }
  .rt-title {
    font-size: 18px;
  }
  .rt-header-container {
    margin-bottom: 10px;
  }
}
