.article-body{
  font-size: 16px;
  text-align: justify;
  line-height: 1.8;
  .paragraph{
    margin-top: 3rem;
    &::first-letter{
      color: #001529;
      padding-right: 8px;
      -webkit-initial-letter: 3;
      initial-letter: 3;
    }
  }
  .paragraph2{
    &::first-letter{
      padding-left: 2rem;
    }
  }
  .paragraph3{
    &::first-letter{
      padding-left: 2rem;
    }
  }
  
  .image-description {
    width: 100%;
    text-align: center;
    color: #001529;
    font-size: 12px;
    padding: 8px;
  }
}
@primary-color: #0A8FDC;