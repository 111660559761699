/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.artist-list-desktop {
  display: none;
}
@media screen and (min-width: 576px) {
  .artist-list-desktop {
    display: block;
  }
}
.artist-list-mobile {
  display: block;
}
@media screen and (min-width: 576px) {
  .artist-list-mobile {
    display: none;
  }
}
@media screen and (min-width: 576px) {
  .artist-footer {
    display: none;
  }
}
.artist-header-pagination {
  padding-left: 8px;
  padding-right: 8px;
}
@media screen and (max-width: 575px) {
  .artist-header-pagination {
    display: none;
  }
}
.artist-content-header {
  display: flex;
  align-items: center;
  flex: 1;
}
.artist-header-checkbox-view {
  margin-right: 20px;
}
[dir=rtl] .artist-header-checkbox-view {
  margin-right: 0;
  margin-left: 20px;
}
.artist-checked-action {
  display: flex;
  align-items: center;
  margin-right: 10px;
}
[dir=rtl] .artist-checked-action {
  margin-right: 0;
  margin-left: 10px;
}
.artist-view-select {
  display: flex;
  align-items: center;
  margin-left: auto;
}
[dir=rtl] .artist-view-select {
  margin-left: 0;
  margin-right: auto;
}
.artist-list-view {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-top: 0;
  padding-bottom: 0;
}
.artist-grid-view {
  padding: 24px;
}
.artist-grid-card {
  border-radius: 16px;
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.1);
  position: relative;
  cursor: pointer;
}
.artist-grid-card .ant-card-body {
  padding: 16px;
}
@media screen and (min-width: 1600px) {
  .artist-grid-card .ant-card-body {
    padding: 20px;
  }
}
.artist-grid-card .artist-list-item-action-hover {
  top: 0;
  transform: inherit;
}
.artist-grid-card:hover .artist-list-item-action-hover {
  right: 0;
  opacity: 1;
  visibility: visible;
  width: 108px;
}
[dir=rtl] .artist-grid-card:hover .artist-list-item-action-hover {
  right: auto;
  left: 0;
}
.artist-grid-card:hover .artist-grid-card-header-action {
  opacity: 0;
  visibility: hidden;
}
.artist-grid-card-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  position: relative;
}
.artist-grid-card-header .anticon-more {
  font-size: 20px;
}
.artist-grid-card-header-checkbox {
  min-width: 36px;
}
.artist-grid-card-header-action {
  position: relative;
  transition: all 0.5s ease;
}
.artist-grid-card-avatar {
  width: 50px;
  height: 50px;
  background-color: #0A8FDC;
}
.artist-grid-card-content {
  text-align: center;
  margin-bottom: 12px;
}
@media screen and (min-width: 992px) {
  .artist-grid-card-content {
    margin-bottom: 16px;
  }
}
@media screen and (min-width: 1600px) {
  .artist-grid-card-content {
    margin-bottom: 20px;
  }
}
.artist-grid-card-title {
  font-weight: 500;
  font-size: 14px;
}
.artist-grid-card-action {
  padding-top: 12px;
  padding-bottom: 12px;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  border-top: solid 2px rgba(0, 0, 0, 0.06);
}
@media screen and (min-width: 1600px) {
  .artist-grid-card-action {
    flex-direction: row;
    justify-content: space-around;
  }
}
.artist-grid-card-action-item {
  padding: 8px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (min-width: 1600px) {
  .artist-grid-card-action-item {
    width: 50%;
  }
}
.artist-grid-card-action-item:not(:first-child) {
  border-top: solid 1px rgba(0, 0, 0, 0.06);
}
@media screen and (min-width: 1600px) {
  .artist-grid-card-action-item:not(:first-child) {
    border-top: 0 none;
    border-left: solid 1px rgba(0, 0, 0, 0.06);
  }
  [dir=rtl] .artist-grid-card-action-item:not(:first-child) {
    border-left: 0 none;
    border-right: solid 1px rgba(0, 0, 0, 0.06);
  }
}
.artist-grid-card-icon {
  font-size: 16px;
  margin-right: 8px;
}
[dir=rtl] .artist-grid-card-icon {
  margin-right: 0;
  margin-left: 8px;
}
.artist-list-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  padding: 7px 24px;
  cursor: pointer;
  position: relative;
}
.artist-list-item.rootCheck {
  font-weight: 300;
  background-color: rgba(10, 143, 220, 0.2);
}
.artist-list-item:hover .artist-list-item-action-hover {
  opacity: 1;
  visibility: visible;
  width: 108px;
}
.artist-list-item:hover .artist-list-item-action {
  opacity: 0;
  visibility: hidden;
}
.artist-list-item:hover .artist-list-item-company {
  transform: translateX(-15px);
}
[dir=rtl] .artist-list-item:hover .artist-list-item-company {
  transform: translateX(15px);
}
.artist-list-item-checkbox-view,
.artist-list-item-star {
  margin-right: 16px;
}
[dir=rtl] .artist-list-item-checkbox-view,
[dir=rtl] .artist-list-item-star {
  margin-right: 0;
  margin-left: 16px;
}
.artist-list-item-company {
  transition: all 0.4s ease;
  transform: translateX(0);
}
.artist-list-avatar-mobile-view {
  margin-right: 14px;
  margin-top: 3px;
}
[dir=rtl] .artist-list-avatar-mobile-view {
  margin-right: 0;
  margin-left: 14px;
}
.artist-list-avatar-mobile-view.checked {
  width: 36px;
  height: 36px;
  line-height: 36px;
  font-size: 18px;
  background-color: #0A8FDC;
  color: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.artist-list-item-avatar-view {
  position: relative;
}
.artist-list-item-avatar {
  background-color: #0A8FDC;
}
.artist-list-item-title {
  margin-right: 16px;
  margin-left: 16px;
  font-weight: 500;
  flex: 1;
}
.artist-list-item-col {
  flex: 1;
  margin-right: 16px;
  display: none;
}
[dir=rtl] .artist-list-item-col {
  margin-right: 0;
  margin-left: 16px;
}
@media screen and (min-width: 768px) {
  .artist-list-item-col {
    display: block;
  }
}
@media screen and (min-width: 576px) {
  .artist-list-item-email {
    display: block;
  }
}
.artist-list-item-action {
  margin-left: auto;
  display: flex;
  align-items: center;
  font-size: 20px;
  transition: all 0.5s ease;
}
[dir=rtl] .artist-list-item-action {
  margin-left: 0;
  margin-right: auto;
}
.artist-list-export-icon {
  margin-left: 8px;
  display: none;
}
[dir=rtl] .artist-list-export-icon {
  margin-left: 0;
  margin-right: 8px;
}
@media screen and (min-width: 576px) {
  .artist-list-export-icon {
    display: block;
  }
}
.artist-list-item-menu-view {
  margin-left: 8px;
}
[dir=rtl] .artist-list-item-menu-view {
  margin-left: 0;
  margin-right: 8px;
}
.artist-search {
  position: relative;
  max-width: 165px;
}
.artist-search .ant-input {
  padding: 8px 14px;
  height: 36px;
}
.artist-search .ant-input-search-button {
  height: 36px;
  width: 36px;
  box-shadow: none;
}
.artist-search .ant-input-search-button .anticon svg {
  display: block;
}
.artist-list-item-action-hover {
  display: flex;
  align-items: center;
  transform: translateY(-50%);
  transition: all 0.4s ease;
  position: absolute;
  right: 24px;
  top: 50%;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  width: 0;
  overflow: hidden;
}
[dir=rtl] .artist-list-item-action-hover {
  right: auto;
  left: 24px;
}
.artist-list-item-mobile {
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  padding: 7px 24px;
  cursor: pointer;
  position: relative;
}
.artist-list-item-mobile.rootCheck {
  font-weight: 300;
  background-color: rgba(10, 143, 220, 0.2);
}
.artist-list-item-main-content-mobile {
  position: relative;
  width: calc(100% - 60px);
  display: flex;
  align-items: center;
  padding-right: 10px;
}
[dir=rtl] .artist-list-item-main-content-mobile {
  padding-right: 0;
  padding-left: 10px;
}
.artist-list-item-content-mobile {
  display: flex;
  flex-direction: column;
  color: #6b7280;
}
.artist-list-item-action-mobile {
  position: relative;
  display: flex;
  align-items: center;
  min-width: 60px;
}
.artist-list-item-star-mobile {
  margin-left: 6px;
}
[dir=rtl] .artist-list-item-star-mobile {
  margin-left: 0;
  margin-right: 6px;
}
.artist-list-export-mobile-icon {
  font-size: 20px;
}
.artist-list-item-mobile-title {
  font-weight: 500;
  color: #111827;
}
