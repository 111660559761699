/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.btn-view {
  padding-top: 8px;
  display: flex;
  align-items: center;
}
.confirmation-btn {
  margin-right: 8px;
  font-weight: 500;
}
.para-text {
  color: #757575;
}
