/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.contact-simple-map {
  margin-bottom: 20px;
  max-height: 40%;
}
.contact-embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
  max-height: 300px;
}
.contact-embed-responsive:before {
  display: block;
  content: '';
}
.contact-embed-responsive .contact-embed-responsive-item,
.contact-embed-responsive iframe,
.contact-embed-responsive embed,
.contact-embed-responsive object,
.contact-embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
.contact-embed-responsive-21by9:before {
  padding-top: 26%;
}
@media screen and (max-width: 499px) {
  .contact-embed-responsive-21by9:before {
    padding-top: 100%;
  }
}
