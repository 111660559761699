/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.general-stats {
  display: flex;
  align-items: center;
}
.card-hover {
  cursor: pointer;
}
.general-stats-content {
  width: calc(100% - 50px);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  color: #6b7280;
}
.general-stats-content p {
  margin-bottom: 0;
}
.general-stats-content h3 {
  margin-bottom: 0;
  font-size: 18px;
}
@media screen and (min-width: 992px) {
  .general-stats-content {
    width: calc(100% - 70px);
  }
}
@media screen and (min-width: 1200px) {
  .general-stats-content {
    width: calc(100% - 50px);
  }
}
@media screen and (min-width: 1600px) {
  .general-stats-content {
    width: calc(100% - 90px);
  }
}
.general-stats-badge {
  border-radius: 30px;
  color: #fff;
  padding: 4px 12px;
  font-size: 12px;
  font-weight: 500;
  display: inline-block;
  min-width: 80px;
  text-align: center;
}
.general-stats-avatar {
  min-width: 46px;
  height: 46px;
  width: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  margin-right: 16px;
  font-size: 18px;
}
[dir=rtl] .general-stats-avatar {
  margin-right: 0;
  margin-left: 16px;
}
.general-stats-avatar svg {
  display: block;
}
@media screen and (min-width: 992px) {
  .general-stats-avatar {
    min-width: 54px;
    height: 54px;
    width: 54px;
    font-size: 20px;
  }
}
@media screen and (min-width: 1600px) {
  .general-stats-avatar {
    min-width: 60px;
    height: 60px;
    width: 60px;
    font-size: 24px;
  }
}
