/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.register-width {
  width: 60%;
}
@media screen and (max-width: 575px) {
  .register-width {
    width: 55%;
  }
}
.bank-name {
  width: 200px;
}
@media screen and (max-width: 575px) {
  .bank-name {
    width: 100%;
    margin-bottom: 20px;
  }
}
.bank-account {
  width: initial;
}
@media screen and (max-width: 575px) {
  .bank-account {
    width: 100%;
  }
}
.ant-divider {
  margin-inline: auto;
  display: flex;
  justify-content: center;
}
@media (max-width: 767px) {
  .ant-divider {
    max-width: 400px;
  }
}
@media (max-width: 479px) {
  .ant-divider {
    max-width: 300px;
  }
}
