/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.info-upload {
  margin-bottom: 20px;
}
.info-upload .ant-form-item-control-input-content {
  display: flex;
  align-items: center;
}
.info-upload-avatar {
  margin-right: 16px;
  width: 50px;
  height: 50px;
}
[dir=rtl] .info-upload-avatar {
  margin-right: 0;
  margin-left: 16px;
}
@media screen and (min-width: 992px) {
  .info-upload-avatar {
    width: 64px;
    height: 64px;
  }
}
.info-upload-content {
  flex: 1;
  font-size: 12px;
  color: #6b7280;
}
.info-upload-content p {
  margin-bottom: 0;
}
.info-upload-btn-group {
  margin-bottom: 6px;
  display: flex;
  align-items: center;
}
.info-upload-btn-group .dropzone {
  margin-right: 10px;
}
[dir=rtl] .info-upload-btn-group .dropzone {
  margin-right: 0;
  margin-left: 10px;
}
.info-upload-btn-group .ant-btn {
  height: 30px;
  padding: 3.5px 12px;
}
.user-profile-group-btn {
  position: relative;
}
.user-profile-group-btn .ant-btn + .ant-btn {
  margin-left: 12px;
}
[dir=rtl] .user-profile-group-btn .ant-btn + .ant-btn {
  margin-left: 0;
  margin-right: 12px;
}
.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  color: black;
}
