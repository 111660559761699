/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.contact-detail-modal {
  position: relative;
}
.contact-detail-modal .ant-modal-body {
  padding: 0;
}
.contact-detail-modal .ant-modal-close {
  display: none;
}
.contact-detail-modal:hover .contact-action-hover {
  opacity: 1;
  visibility: visible;
}
.contact-detail-modal-header {
  position: relative;
  border-bottom: solid 1px rgba(0, 0, 0, 0.12);
  padding: 20px;
}
.contact-detail-modal-user {
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media screen and (min-width: 576px) {
  .contact-detail-modal-user {
    margin-top: -12px;
  }
}
.contact-detail-modal-user h4 {
  font-size: 16px;
  font-weight: 500;
}
.contact-detail-modal-user-avatar {
  width: 55px;
  height: 55px;
  margin-bottom: 8px;
  font-size: 20px;
  padding: 10px;
}
.contact-detail-modal-content {
  padding: 20px 24px;
}
.contact-detail-modal-scrollbar {
  position: relative;
  max-height: 400px;
}
.contact-detail-modal-scrollbar .ant-row > .ant-col {
  margin-bottom: 0;
}
.contact-action {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: #959ca9;
}
.contact-action-hover {
  display: flex;
  align-items: center;
  opacity: 0;
  transition: all 0.3s ease;
  visibility: hidden;
}
.contact-note {
  padding: 20px 20px 20px 0;
}
[dir=rtl] .contact-note {
  padding-left: 20px;
  padding-right: 0;
}
@media screen and (min-width: 992px) {
  .contact-note {
    padding-right: 32px;
  }
  [dir=rtl] .contact-note {
    padding-right: 0;
    padding-left: 32px;
  }
}
@media screen and (min-width: 1600px) {
  .contact-note {
    padding-right: 40px;
  }
  [dir=rtl] .contact-note {
    padding-right: 0;
    padding-left: 40px;
  }
}
.contact-detail-modal-item {
  padding: 20px 0;
}
.contact-detail-modal-item-title {
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 8px;
}
.contact-detail-modal-item-content {
  position: relative;
}
.contact-other-detail-item {
  display: flex;
  align-items: center;
}
.contact-other-detail-item:not(:first-child) {
  margin-top: 8px;
}
.contact-other-detail-item-content {
  margin-left: 8px;
  color: #6b7280;
  font-size: 14px;
}
[dir=rtl] .contact-other-detail-item-content {
  margin-left: 0;
  margin-right: 8px;
}
.contact-other-detail-item-icon {
  font-size: 16px;
  color: #757575;
}
.custom-select .ant-select-selector {
  padding: 0 !important;
}
