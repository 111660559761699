/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.container-header-custom {
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 576px) {
  .container-header-custom {
    flex-direction: row;
    justify-content: space-between;
  }
}
@media screen and (min-width: 1600px) {
  .container-header-custom {
    padding-top: 16px;
  }
}
.link-icon {
  padding-right: 4px;
  font-size: 20px;
}
[dir=rtl] .link-icon {
  padding-right: 0;
  padding-left: 4px;
}
@media screen and (min-width: 576px) {
  .header-title {
    padding-right: 12px;
    flex: 1;
  }
  [dir=rtl] .header-title {
    padding-right: 0;
    padding-left: 12px;
  }
}
.header-title .title-h3 {
  color: #111827;
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 0;
}
@media screen and (min-width: 576px) {
  .header-title .title-h3 {
    font-size: 20px;
  }
}
.header-title .text-base {
  font-size: 15px;
  font-weight: 400;
  color: #6b7280;
  margin: 0 !important;
}
