/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.star-icon {
  color: #F4B400;
}
.star-icon .anticon {
  display: block;
}
.star-filled {
  color: #F4B400;
}
