/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.header-login {
  margin-left: 10px;
}
@media screen and (min-width: 1200px) {
  .header-login {
    margin-left: 40px;
  }
}
.header-login:hover {
  color: #ffffff;
}
.header-login:hover::after {
  border-bottom: none !important;
}
.ant-menu-submenu {
  z-index: 3000;
}
.menu-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.logo {
  font-size: 24px;
  font-weight: bold;
  color: #fff;
}
.menu-icon {
  display: none;
  font-size: 24px;
  color: #fff;
  cursor: pointer;
}
@media screen and (max-width: 992px) {
  .menu-wrapper {
    flex-direction: row;
    align-items: flex-start;
  }
  .menu-wrapper .ant-menu {
    display: none;
  }
  .header-login {
    display: none;
  }
  .lang {
    display: none;
  }
  .header {
    height: 75px;
  }
  .menu-icon {
    display: block;
  }
}
@media screen and (max-width: 576px) {
  .responsive-logo {
    width: 60px !important;
    height: 50px;
  }
}
