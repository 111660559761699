.song-content-header {
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: space-between;

  .song-search {
    position: relative;
    max-width: 165px;
  
    & .ant-input {
      padding: 8px 14px;
      height: 36px;
    }
  
    & .ant-input-search-button {
      height: 36px;
      width: 36px;
      box-shadow: none;
  
      & .anticon svg {
        display: block;
      }
    }
  }
}


@primary-color: #0A8FDC;