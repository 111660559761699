/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.custom-footer {
  height: 20rem;
  background-color: #f5f5f5;
  padding: 0px 120px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media screen and (max-width: 576px) {
  .custom-footer {
    padding: 0px 20px;
  }
}
.custom-footer .footer-menu {
  padding: 40px;
}
.custom-footer .footer-menu h4 a {
  color: #292929 !important;
}
.custom-footer .footer-menu p a {
  color: #707070 !important;
}
.custom-footer .footer-menu .footer-logo-text {
  color: '#0A8FDC';
  font-weight: 300;
  margin-bottom: 0px;
  margin-top: 3px;
  margin-left: 3px;
  color: #0A8FDC;
}
.custom-footer .copy-right {
  padding: 20px;
  border-top: 1px solid #cccbcb;
  text-align: center;
  color: #757575;
}
