/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.contact-address {
  position: relative;
}
@media screen and (min-width: 992px) {
  .contact-address {
    margin-left: 20px;
  }
  [dir=rtl] .contact-address {
    margin-left: 0;
    margin-right: 20px;
  }
}
.contact-address-item {
  background-color: #F4F7FE;
  border-radius: 4px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  padding: 14px 20px;
}
.contact-address-item p {
  margin-bottom: 0;
}
.contact-address-item .avatar-icon {
  width: 40px;
  height: 40px;
  background-color: #fff;
  color: #0A8FDC;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 14px;
}
[dir=rtl] .contact-address-item .avatar-icon {
  margin-right: 0;
  margin-left: 14px;
}
@media screen and (min-width: 768px) {
  .contact-address-item .avatar-icon {
    margin-right: 16px;
    width: 48px;
    height: 48px;
  }
  [dir=rtl] .contact-address-item .avatar-icon {
    margin-right: 0;
    margin-left: 16px;
  }
}
.contact-address-item .avatar-icon svg {
  font-size: 20px;
}
