/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.list-footer {
  padding: 10px;
  color: #6b7280;
  display: flex;
  justify-content: center;
}
.loader-progress {
  width: 100%;
  display: flex;
  justify-content: center;
  color: #6b7280;
  padding: 8px;
  border-top: solid 1px rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
}
.loader-progress span {
  margin-left: 8px;
}
[dir=rtl] .loader-progress span {
  margin-left: 0;
  margin-right: 8px;
}
.empty-list-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 16px;
  min-height: 450px;
  height: 100%;
  padding: 20px;
  font-size: 16px;
  color: #aeafb8;
}
.empty-list-container p {
  font-size: 14px;
  color: #111827;
}
.empty-list-container h4 {
  font-size: 18px;
  color: #6b7280;
  margin-bottom: 12px;
}
.flex-row {
  flex-direction: row;
}
