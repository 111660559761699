/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.artist-content {
  padding: 0;
}
@media screen and (max-width: 768px) {
  .artist-content {
    padding: 20px;
  }
}
.ant-space {
  width: 100%;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 23px 40px;
}
.ant-collapse-content > .ant-collapse-content-box {
  padding: 40px;
}
.ant-collapse {
  border-radius: 20px;
}
.ant-collapse-item:last-child > .ant-collapse-content {
  border-radius: 0 0 20px 20px;
}
.ant-collapse > .ant-collapse-item:last-child {
  border-radius: 0 0 20px 20px;
}
.artist-image-bg-1 {
  background: linear-gradient(0deg, rgba(0, 9, 44, 0.75), rgba(0, 9, 44, 0.75)), url(../../../assets/images/audience.jpg);
  background-size: cover;
  height: 340px;
  background-position: center 65%;
  display: flex !important;
  align-items: center;
  justify-content: center;
  margin-bottom: 80px;
}
.artist-image-bg-1 .ant-typography {
  color: #ffffff;
  text-align: center;
  font-size: 32px;
  font-weight: 500;
}
@media screen and (min-width: 768px) {
  .artist-image-bg-1 .ant-typography {
    width: 900px;
  }
}
