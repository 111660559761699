/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.send-message {
  max-width: 500px;
  margin-bottom: 20px;
}
@media screen and (min-width: 768px) {
  .send-message {
    margin-bottom: 24px;
  }
}
@media screen and (min-width: 992px) {
  .send-message {
    margin-bottom: 28px;
  }
}
.send-message-title {
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 600;
}
@media screen and (min-width: 768px) {
  .send-message-title {
    font-size: 20px;
  }
}
