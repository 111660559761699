/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.metrics-stats-card {
  text-align: center;
}
.metrics-stats-card .ant-card-body {
  padding: 24px !important;
}
.metrics-stats-card p {
  color: #6b7280;
  margin-bottom: 8px;
}
.metrics-stats-card h3 {
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 0;
}
.metrics-stats-avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  margin: 0 auto 16px;
  height: 50px;
  width: 50px;
}
@media screen and (min-width: 768px) {
  .metrics-stats-avatar {
    height: 90px;
    width: 90px;
  }
}
.metrics-stats-avatar > img {
  width: auto;
  height: auto;
}
