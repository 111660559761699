/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.manager-modal {
  position: relative;
}
.manager-modal .ant-modal-body {
  padding: 0;
}
.manager-modal-scrollbar {
  height: 400px;
}
.manager-form {
  position: relative;
}
.manager-form .form-field {
  width: 100%;
  margin-bottom: 16px;
}
@media screen and (min-width: 1600px) {
  .manager-form .form-field {
    margin-bottom: 24px;
  }
}
.manager-form .ant-picker {
  width: 100%;
}
.manager-form-header {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: solid 1px rgba(0, 0, 0, 0.12);
}
.manager-form-header .dropzone {
  margin-bottom: 0;
}
.manager-form-header .dropzone:focus {
  outline: 0;
}
.manager-form-avatar {
  width: 55px;
  height: 55px;
  margin-bottom: 8px;
  cursor: pointer;
}
.manager-form-header-title {
  font-weight: 500;
}
.manager-form-content {
  position: relative;
}
.manager-form-content-item {
  padding: 20px;
}
@media screen and (min-width: 992px) {
  .manager-form-content-item {
    padding-left: 32px;
    padding-right: 32px;
  }
}
@media screen and (min-width: 1600px) {
  .manager-form-content-item {
    padding-left: 40px;
    padding-right: 40px;
  }
}
.manager-form-content-item:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.manager-form-content-item-title {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 16px;
}
@media screen and (min-width: 1600px) {
  .manager-form-content-item-title {
    margin-bottom: 24px;
  }
}
.manager-form-content-field {
  position: relative;
}
.manager-form-footer {
  padding: 16px 32px;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 0 0 16px 16px;
  text-align: right;
}
[dir=rtl] .manager-form-footer {
  text-align: left;
}
.manager-form-footer .ant-btn {
  min-width: 100px;
}
.manager-form-footer .ant-btn:not(:first-child) {
  margin-left: 10px;
}
[dir=rtl] .manager-form-footer .ant-btn:not(:first-child) {
  margin-left: 0;
  margin-right: 10px;
}
.manager-form-btn {
  padding: 8px 32px;
}
