/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.profile-connection {
  position: relative;
  margin-left: -20px;
  margin-right: -20px;
  margin-top: 12px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.profile-connection-title {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 16px;
}
.member-item {
  position: relative;
  height: 100%;
  padding-bottom: 16px;
}
.member-card {
  border: solid 1px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}
.member-card-title {
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 16px;
  color: #6b7280;
}
.member-info {
  display: flex;
}
.member-thumb {
  margin-right: 12px;
}
[dir=rtl] .member-thumb {
  margin-right: 0;
  margin-left: 12px;
}
.member-thumb .ant-avatar {
  width: 40px;
  height: 40px;
}
.member-info-content {
  flex: 1;
}
.member-info-content h5 {
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 10px;
}
.member-info-content .ant-btn {
  font-size: 12px;
  height: 30px;
  padding: 3.5px 12px;
}
.disconnect-btn {
  background-color: rgba(240, 79, 71, 0.1);
  color: #F04F47;
  border-color: rgba(240, 79, 71, 0.1);
}
.disconnect-btn:hover,
.disconnect-btn:focus {
  background-color: rgba(240, 79, 71, 0.2);
  color: #F04F47;
  border-color: rgba(240, 79, 71, 0.2);
}
